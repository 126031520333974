import './App.css';
import pub from './pub'
import priv from './priv'

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import PropTypes from 'prop-types'
////////////////////////////////////////////////////////////
// 1. Click the public page
// 2. Click the protected page
// 3. Log in
// 4. Click the back button, note the URL each time

  

    



class AuthExample extends React.Component {
  state = {
    niggers: '123'
  };

  render() {
    return (
      <Router>
      <div id='bbg'>
      <AuthButton />
      <Route exact path="/" component={pub} />
      <Route path="/login" component={Login} />
      <PrivateRoute path="/protected" component={priv} />
    </div>
  </Router>
    )}
}


const fakeAuth = {
  isAuthenticated: false,
  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 10); // fake async
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 10);
  }
};

const AuthButton = withRouter(
  ({ history }) =>
    fakeAuth.isAuthenticated ? (
        <button class='rr'
          onClick={() => {
            fakeAuth.signout(() => history.push("/"));
          }}
        >
          🚪
        </button>
    ) : (
      <div></div>
    )
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      fakeAuth.isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class Login extends React.Component {
  constructor(props){
    super(props)

  }
  state = {
    redirectToReferrer: false
  };
  


  checking = () => {
    var lan = document.getElementById('inputPassword').value;
    if(lan === "0000"){
      this.login()
    }else{
      document.getElementById('inputPassword').value = "";
    }
  }
  login = () => {
      fakeAuth.authenticate(() => {
        this.setState({ redirectToReferrer: true });
      });
  };

  onEnterPress = (e) => {
    if(e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.checking();
    }
  }

  
  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (

<div class="text-center">
    <div class="form-signin">
        <input type="tel" id="inputPassword" onKeyDown={this.onEnterPress} name="password" class="form-control" pattern="[0-9]*" placeholder="Password" required novalidate></input>
        <button class="btn btn-lg btn-primary btn-block" onClick={this.checking}>Sign in</button>
    </div>
    <Link to="/"><div class="ff">😪</div></Link>
</div>
     
    );
  }
}

export default AuthExample;