import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

const config = {
    apiKey: "AIzaSyBXNHETHjwZ3lV70lcZawWWDe_KPOiIPpg",
    authDomain: "nodedemo2.firebaseapp.com",
    databaseURL: "https://nodedemo2.firebaseio.com",
    projectId: "nodedemo2",
    storageBucket: "nodedemo2.appspot.com",
    messagingSenderId: "713034664286",
    appId: "1:713034664286:web:463f51afcdfaf88ad527cf",
    measurementId: "G-WCS9DTT6G8"
};

const firebase = initializeApp(config);
const db = getFirestore();

export default db;