import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import db from "./firebase";
import logo from './logo.svg';
import './App.css';

import { getDoc, doc, setDoc, onSnapshot } from "firebase/firestore";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diss: true,
      inivalue: 'loading...',
      value: 'dont type first',
      status: '😪',
      name: '',
      typing: false,
      typingTimeout: 0
    };

    this.handleChange = this.handleChange.bind(this);
    this.add = this.add.bind(this);
    this.changeName = this.changeName.bind(this);
    this.get = this.get.bind(this);
    this.pullFromDB();
  }


  handleChange(e) {
    this.setState({ value: e.target.value });

  }
  changeName = (event) => {
    const self = this;
    self.state.inivalue = event.target.value

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      status: '✍️',
      name: event.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.setState({
          status: '👍',
          value: self.state.name
        })
        self.add(self.state.name);
      }, 1000)
    });
  }
  async add(e) {
    await setDoc(doc(db, 'pastes', 'private'), {
      daty: e
    }, { merge: true });
  }
  get() {
    let vava = ''
    getDoc(doc(db, 'pastes', 'private')).get().then(function (doc) {
      if (doc.exists) {
        console.log("Document data:", doc.data().daty);
        vava = doc.data().daty
      }
    })
  }
  // componentDidMount() {
  //   const ref = collection('data').doc('data')
  //   ref.get().then((doc) => {
  //     if (doc.exists) {
  //       this.setState({
  //         value: doc.data().daty
  //       });
  //     } else {
  //       console.log("No such document!");
  //     }
  //   });
  // }
  pullFromDB() {





    // We listen for live changes to our events collection in Firebase
    onSnapshot(doc(db, 'pastes', 'private'), doc => {
      let data = doc.data().daty

      this.setState({
        diss: false,
        value: data,
        inivalue: data
      });

    })
  }




  render() {
    return (
      <div id='bbg'>
        <Link to="/"><div class="ff">{this.state.status}</div></Link>
        <textarea id='bbd' class="form-control" type="text" value={this.state.inivalue} onChange={this.changeName} disabled={this.state.diss} />
      </div>
    );
  }
}

export default withRouter(App);
